<template>
  <div class="date-of-birth">
    <el-form-item class="date-of-birth-row">
      <label for="text">
        Date of Birth
        <span class="star" v-if="displayRequired">*</span>
      </label>
      <div class="FormDate" @keyup.capture="updateValue">
        <input
          ref="month"
          v-model="month"
          class="FormDate__input FormDate__input--month"
          placeholder="MM"
          @input="updateMonth"
          maxlength="2"
          @blur="updateMonthValue"
          v-on:keypress="NumbersOnly"
          :readonly="readonly"
          :disabled="!isBillingUser"
        />
        <span v-if="showDay && showMonth" class="FormDate__divider">/</span>

        <input
          ref="day"
          v-model="day"
          class="FormDate__input FormDate__input--day"
          placeholder="DD"
          @input="updateDay"
          maxlength="2"
          @blur="updateDayValue"
          v-on:keypress="NumbersOnly"
          :readonly="readonly"
          :disabled="!isBillingUser"
        />
        <span v-if="showYear && (showDay || showMonth)" class="FormDate__divider">/</span>
        <input
          ref="year"
          v-model="year"
          maxlength="4"
          class="FormDate__input FormDate__input--year"
          placeholder="YYYY"
          @blur="updateYearValue"
          v-on:keypress="NumbersOnly"
          :readonly="readonly"
          :disabled="!isBillingUser"
        />
      </div>
      <p class="err" v-if="validataionErrors && validataionErrors[errorKey]">
        {{ validataionErrors[errorKey] }}
      </p>
    </el-form-item>
  </div>
</template>

<script>
import momentTZ from "moment-timezone";
import moment from "moment";
export default {
  name: "DobNewFormat",
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    showDay: {
      type: Boolean,
      default: true,
    },
    showMonth: {
      type: Boolean,
      default: true,
    },
    showYear: {
      type: Boolean,
      default: true,
    },
    dobData: { required: true },
    validataionErrors: { required: false },
    errorKey: { required: false },
    displayRequired: { default: true },
    readonly: {
    type: Boolean,
    default: false,
  },
  },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      formData: "",
    };
  },
  mounted() {
    this.setExistedValue();
    this.formData = this.dobData;
  },

  methods: {
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetDate() {
      this.day = "";
      this.month = "";
      this.year = "";
    },
    updateDay() {
      if (!this.day.length || parseInt(this.day, 10) < 4) return;
      else if (this.showYear) this.$refs.year.select();
    },
    updateMonthValue() {
      if (this.month) {
        this.month = this.month.padStart(2, "0");
      }
    },
    updateDayValue() {
      if (this.day) {
        this.day = this.day.padStart(2, "0");
      }
    },
    updateYearValue() {
      if (this.year) {
        this.year = this.year.padStart(4, "0");
      }
    },
    updateMonth() {
      if (!this.month.length || parseInt(this.month, 10) < 2) return;
      if (this.month) this.$refs.day.select();
    },
    updateValue() {
      if (this.year && this.month && this.day) {
        if (this.year.length == 4 && this.year < 1900) {
          this.year = 1900;
        }
        this.formData["date_of_birth"] = this.year + "-" + this.month + "-" + this.day;

        this.checkValidDOBDate();
      } else if (!this.year && !this.month && !this.day) {
        this.formData["date_of_birth"] = "";
      } else if (!this.day || !this.month || !this.year) {
        this.formData["date_of_birth"] = "";
      }
    },
    checkValidDOBDate() {
      const dob = new Date();
      if (this.formData["date_of_birth"] && this.formData["date_of_birth"].length == 10) {
        const dobDate = moment(this.formData["date_of_birth"]);
        if (!dobDate.isValid()) {
          this.$message({
            message: "Invalid DOB!",
            type: "warning",
          });
          return false;
        } else if (dobDate > dob) {
          this.$message({
            message: "Future Dates Not Allowed",
            type: "warning",
          });
        }
      }

      return true;
    },
    setExistedValue() {
      if (this.formData["date_of_birth"]) {
        let date = momentTZ.utc(this.formData["date_of_birth"]).format("YYYY-MM-DD");

        let dates = date.split("-");
        if (dates.length) {
          this.year = dates[0];
          this.month = dates[1];
          this.day = dates[2];
        }
        if (this.year && this.year < 1900) {
          this.year = 1900;
        }
        this.formData["date_of_birth"] = this.year + "-" + this.month + "-" + this.day;
      }
    },
  },

  watch: {
    formData: function () {
      setTimeout(() => {
        this.setExistedValue();
      }, 100);
      if (!this.formData.date_of_birth) this.resetDate();
    },

    dobData: {
      immediate: true,
      deep: true,
      handler: function () {
        this.formData = this.dobData;
      },
    },
  },
};
</script>

<style lang="scss">
.FormDate {
  // $spacing: 0.75em;
  background: #ffffff;
  padding-right: 25px !important;
  padding-left: 5px;
  color: #000;
  border: 0.922417px solid rgba(156, 156, 156, 0.5);
  font-weight: 500;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 30px;
  margin: 5px 0;
  border-radius: 3.56227px;
  height: 30px !important;

  &__input {
    border: none;
    text-align: center;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      // padding-left: $spacing;
    }

    &:last-child {
      // padding-right: $spacing;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 3em;
    }

    &--year {
      width: 4em;
    }
  }

  &__divider {
    //   padding-top: $spacing;
    //   padding-bottom: $spacing;
    pointer-events: none;
    color: #bdbdbd;
  }
}
</style>
