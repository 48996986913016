<template>
  <div>
    <DLWFormHeader v-if="displayApp == 'DLW'"></DLWFormHeader>
    <MicrogenFormHeader v-else></MicrogenFormHeader>
  </div>
</template>
<script>
import DLWFormHeader from "@/components/patientReport/header/dlw/FormLogoHeader";
import MicrogenFormHeader from "@/components/patientReport/header/microgen/FormLogoHeader";
export default {
  props: ["displayApp"],

  components: {
    DLWFormHeader,
    MicrogenFormHeader,
  },
};
</script>
