<template>
  <div class="logo">
    <img src="@/assets/img/dlw-logo-new.svg" alt="Logo" width="250px" />
    <p class="sub-line">
      234 Aquarius Drive, Suite 100, 111, Homewood, AL 35209
    </p>
    <p class="sub-line">
      Website:
      <a href="https://devlabwerks.com/" target="_blank"
        >https://devlabwerks.com/</a
      >
    </p>

    <p class="sub-line">
      Phone:
      <a href="tel:+12059948266">+1 (205) 994-8266</a>
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.logo {
  padding: 1em;
  text-align: center;
  img {
    margin-bottom: 0.5em;
    width: 120px;

    @media (max-width: 576px) {
      width: 90px;
    }
  }
  .sub-line {
    font-size: 12px;
    line-height: 1.5;
    color: #444444;
    margin: 5px 0px;
    a {
      color: #f24a58;
    }
  }
}
</style>
