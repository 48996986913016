<template>
  <div class="logo">
    <img src="@/assets/img/mgh-logo-new.svg" alt="Logo" width="250px" />
    <p class="sub-line">
      Microgen Health Inc 14225 Sullyfield Circle, Suite E, Chantilly, Virginia
      20151
    </p>
    <p class="sub-line">
      Website:
      <a href="https://microgenhealth.com/" target="_blank"
        >https://microgenhealth.com</a
      >
    </p>

    <p class="sub-line">
      Phone:
      <a href="tel:15717751973"> +1 571-775-1973</a>
    </p>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  padding: 2em 1em 1em;
  text-align: center;
  img {
    margin-bottom: 0.5em;
  }
  .sub-line {
    font-size: 12px;
    line-height: 1.5;
    color: #444444;
    margin: 5px 0px;
    a {
      color: #f24a58;
    }
  }
}
</style>
